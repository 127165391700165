.sidebar {
    display: flex;
    position: fixed;
    width: 100%;
    height: calc(100% - 40px);
    background-color: rgb(50, 50, 50);
    z-index: 10001;
    /* border-top: 1px solid rgb(207, 207, 207); */
    top: 40px;
    transform: translateX(100%);
    padding-top: 10px;
    flex-direction: column;
    align-items: center;
}

header {
    display: flex;
    width: 100%;
    height: 40px;
    background-color: rgb(44, 44, 44);
    z-index: 3;
    overflow: hidden;
    justify-content: center;
    align-self: center;
}
@media (min-width: 650px) {
    header {
        justify-content: left;
    }
}

.page_title {
    height: min-content;
    display: flex;
    color: rgb(207, 207, 207);
    align-self: center;
    font-size: 20px;
}

.burger {
    display: flex;
    position: absolute;
    height: 20px;
    width: 25px;
    margin-right: 5px;
    padding: 10px;
    cursor: pointer;
    right: 5px;
}

@media (min-width: 650px) {
    .burger {
        display: none;
    }
}

.burger > img {
    height: 2px;
    position: absolute;
    width: 25px;
}
.burger > img#p1 {
    top: 10px;
}
.burger > img#p2 {
    top: calc(50% - 1px);
    transition: 0.4s;
}
.burger > img#p3 {
    bottom: 10px
}

.burger.open > img#p1 {
    animation: piece1 0.3s linear forwards;
}
.burger.open > img#p2 {
    opacity: 0%;
}
.burger.open > img#p3 {
    animation: piece3 0.3s linear forwards;
}
.burger.close > img#p1 {
    animation: piece11 0.4s linear forwards;
}
.burger.close > img#p3 {
    animation: piece33 0.4s linear forwards;
}
@keyframes piece1 {
    0% {transform: translateY(0px);}
    75% {transform: translateY(9px);}
    100% {
        transform: translateY(9px)rotate(45deg);
    }
}
@keyframes piece11 {
    0% {transform: translateY(9px)rotate(45deg);}
    50% {transform: translateY(9px)rotate(0deg);}
    100% {
        transform: translateY(0px)rotate(0deg);
    }
}
@keyframes piece3 {
    0% {transform: translateY(0px);}
    75% {transform: translateY(-9px);}
    100% {
        transform: translateY(-9px)rotate(-45deg);
    }
}
@keyframes piece33 {
    0% {transform: translateY(-9px)rotate(-45deg);}
    50% {transform: translateY(-9px)rotate(0deg);}
    100% {
        transform: translateY(0px)rotate(0deg);
    }
}

.sidebar_option {
    display: flex;
    height: 26px;
    width: fit-content;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
}

.sidebar_option > a {
    color: rgb(184, 184, 184);
    font-size: 20px;
    text-decoration: none;
}
.sidebar_option > a.on {
    color: white;
}

.sidebar_option > div {
    display: flex;
    position: absolute;
    height: 1.5px;
    width: 0%;
    background-color: rgb(207, 207, 207);
    bottom: 0;
    transition: 0.3s;
}
.sidebar_option > div.on {
    width: 100%;
}
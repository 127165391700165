.wrapper {
    overflow: scroll;
    padding: 10px 20px;
    box-sizing: border-box;
    min-width: 800px;
}

.content {
    display: flex;
    width: 100%;
    justify-self: center;
    flex-direction: column;
    margin: 0 auto;
}
.wrapper::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
}

.top {
    display: flex;
    padding: 0 10px;
    border-bottom: 1px solid rgb(114, 114, 114);
    flex-wrap: wrap;
}
.top > div {
    height: 70px;
    align-items: flex-end;
    width: fit-content;
    margin-bottom: 16px;
}

.table_holder {
    width: 100%;
    overflow-x: scroll;
}
.table_holder::-webkit-scrollbar {
    width: 0px;
}

.table {
    width: 100%;
    flex-direction: column;
    /* margin-top: -50px; */
    border-collapse: collapse;
}

.table_options_holder {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    color: white;
    margin-top: -10px;
    /* background-color: #6A6A6A; */
}
.table_options_holder > span {
    height: fit-content;
    font-size: 26px;
    font-weight: 300;
}

.table_options {
    display: flex;
    margin-right: 10px;
}

.option {
    display: flex;
    padding: 8px 10px;
    background-color: #E3702C;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
}

.thead {
    color: white;
}

.thead > tr {
    text-align: left;
    text-wrap: nowrap;
    color: #9c9c9c;
}

.thead > tr > th {
    display: table-cell;
    vertical-align: middle;
    padding: 7px;
    user-select: none;
    cursor: pointer;
    padding-right: 0px;
    user-select: none;
    white-space: nowrap;
}
.thead > tr > th > * {
    user-select: none;
    white-space: nowrap;
    /* font-family: 'Inter', sans-serif */
}
.thead > tr > th > img {
    margin-bottom: -2px;
    margin-left: 2px;
}

.item {
    height: 42px;
    color: rgb(255, 255, 255);
    border-bottom: 1px solid #313131;
    position: relative;
}
.item > * {
    text-align: left;
    padding: 7px;
    white-space: nowrap;
    /* font-family: 'Inter', sans-serif */
}
.item:last-child {
    border-bottom: none;
}
tbody > tr > th {
    font-weight: normal;
}

.percent_active {
    position: absolute;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: left;
    top: 4px;
    background-color: #303030;
    opacity: 1 !important;
    z-index: 1;
    min-width: 180px !important;
    min-height: 92px !important;
    border-radius: 16px;
    padding: 10px !important;
}
.percent_active > div {
    padding: 2px 0;
}
.sorter {
    width: 10px;
}

.head {
    display: flex;
    flex-direction: column;
}

.accounts {
    display: flex;
    height: fit-content;
    position: relative;
    margin-right: 22px;
}
.accounts > div > div > input {
    width: 100px;
    background: none;
    color: white;
    outline: none;
    border: 1px solid #828282;
    border-radius: 6px; 
    padding: 6px;
    text-align: center;
}
.accounts > button {
    display: flex;
    width: 70px;
    height: 40px;
    background: none;
    border: 1px solid #828282;
    color: #fff;
    margin-right: 14px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.1s;
    /* box-sizing: content-box; */
}
.accounts > button.active {
    background-color: #E3702C;
    border: none;
}
.accounts > button:focus {
    outline: none;
}
.accounts > button:hover {
    outline: 1px solid #E3702C;
}

.calendar_holder {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 22px;
}
.calendar_holder > div > div > div > input {
    height: 22px;
    width: 90px;
    background: none;
    color: white;
    outline: none;
    border: 1px solid #828282;
    border-radius: 6px; 
    padding: 6px;
    text-align: center;
    font-size: 14px;
}
.calendar_holder > div > div > input:hover {
    border: 1px solid #B3B3B3;
}
.calendar_holder > span, .calendar_statistic > span, .accounts > span {
    position: absolute;
    top: 0px;
    font-weight: 300;
    font-size: 20px;
    color: rgb(255, 255, 255)
}
.calendar_holder > .calendar_buttons > button {
    display: flex;
    height: 36px;
    width: 80px;
    background: none;
    border: 1px solid #828282;
    color: #fff;
    margin-right: 4px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.1s;
}
.calendar_holder >  .calendar_buttons >button:hover {
    background-color: #be5d25;
    border: none;
}
.calendar_holder > .calendar_buttons > button.active {
    background-color: #E3702C;
    border: none;
}
.calendar_holder > .calendar_buttons > button:focus {
    outline: 1px solid #E3702C;
}
.calendar:focus {
    outline: none;
}

.calendar_statistic {
    display: flex;
    /* min-width: 480px; */
    /* border: 1px solid red; */
    position: relative;
    justify-content: space-between;
}

.statistic {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    text-wrap: nowrap;
}
.statistic span:nth-child(1) {
    color: #9D9D9D;
    font-size: 14px;
}
.statistic span:nth-child(2) {
    color: white;
    font-size: 18px;
    font-weight: 500;
}

.calendar_holder > div > div, .calendar_holder > div > div > div > div {
    background-color: #1f1f1f;
    z-index: 2;
}
.calendar_days, .calendar_days2 {
    color: white !important;
    box-sizing: border-box;
}
.calendar_days:hover {
    background: none !important;
    outline: 1px solid #ec884e;
}
.calendar_days2:hover {
    background: none !important;
    outline: 1px solid #E3702C;
}

.calendar_buttons {
    display: flex;
    width: fit-content;
    align-items: center;
}

/* @media (max-width: 1380px) {
    .calendar_buttons {
        display: none;
    }
} */

.graphs_holder {
    display: flex;
    flex-direction: row;
    position: relative;
}
@media (max-width: 1000px) {
    .chart_sales_holder {
        height: 210px;
        margin-top: 10px;
    }
}

.chart_profits_holder, .chart_sales_dates_holder {
    height: 100%;
    width: calc((100% - 200px)*0.4);
    position: relative;
}

.chart_sales_dates_holder {
    width: calc((100% - 200px)*0.6);
}
.chart_sales_duration_holder, .chart_sales_holder {
    position: relative;
}


@media (min-width: 1500px) {
    .chart_profits_holder, .chart_sales_dates_holder {
        height: 100%;
        width: calc((100% - 460px)*0.4);
        position: relative;
    }
    
    .chart_sales_dates_holder {
        width: calc((100% - 460px)*0.6);
    }

    .chart_sales_holder {
        margin-top: 20px;
    }
}

.chart_sales_duration_holder {
    height: 100%;
}

.chart_profits_holder > span, .chart_sales_dates_holder > span, .chart_sales_duration_holder > span {
    height: calc(100% - 64px);
    width: 100%;
    position: absolute;
    top: 40px;
    z-index: 1;
}
.chart_sales_holder > span {
    height: 220px;
    width: 100vw;
    padding: 0 20px;
    position: absolute;
    top: 26px;
    left: -20px;
    z-index: 1 !important;
    background-color: #1f1f1f;
    box-sizing: border-box;
    line-height: 1;
}